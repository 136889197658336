<template>
  <div class="mar-bot-80">
    <v-container class="pb-2">
      <v-row>
        <v-col cols="1" align="right" offset="11">
          <v-btn icon @click.stop="toggleMenu" class="float-right">
            <v-icon x-large>mdi-menu</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <Menu ref="refMenu"></Menu>
      <v-row v-if="!orderData && loading===false">
        <h2>Не найден</h2>
      </v-row>
      <v-row v-if="orderData && loading===false">
        <v-col class="d-flex justify-center" cols="6" offset="3" >
          <v-img :src="orderData.qrCode" class="qr-image" max-width="300px"></v-img>
        </v-col>
      </v-row>
      <v-row v-if="orderData && loading===false">
        <v-col cols="12">
          <h2 class="text-center">{{ orderData.userName }}, Ваш заказ №{{ token }} от {{ dateFormatted }} принят.</h2>
          <template>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr
                  v-for="(item, i) in orderData.positions"
                  :key="i"
                >
                  <td>{{ item.type ? item.type.title : '' }}</td>
                  <td>{{ item.weight ? item.weight.title : '' }}</td>
                  <td>{{ item.type ? item.type.price : '' }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>

          <h3>Ожидайте звонка оператора для подтверждения заказа</h3>
          <p>Если Вам не позвонили по заказу, или
            у вас остались вопросы свяжитесь с
            нами по телефону <a href="tel:+79381680010">+7(938)168-0010</a>.</p>
        </v-col>
      </v-row>
    </v-container>
    <v-footer padless fixed class="button-block">
      <v-col
        class="text-center"
        cols="6"
        md="6"
        lg="6"
        sm="6"
      >
        <div class="text-decoration-none d-block">
          <v-btn block elevation="2"
             color="orange lighten-1"
             dark
             middle
             @click="goTo('/history')"
             class="next-step"
          > Все заказы
          </v-btn>
        </div>
      </v-col>
      <v-col
        class="text-center"
        cols="6"
        sm="6"
        md="6"
        lg="6"
      >
        <div class="text-decoration-none d-block">
          <v-btn block elevation="2"
                 color="green darken-2"
                 dark
                 middle
                 @click="goTo('/')"
                 class="next-step"
          > На главную
          </v-btn>
        </div>
      </v-col>
  </v-footer>
  </div>
</template>
<script>
import ApiClient from '@/plugins/api-client'
import Menu from '../components/Menu'

export default {
  components: { Menu },
  props: ['date', 'token'],
  data: () => ({
    loading: true,
    orderData: null
  }),
  created () {
    const client = new ApiClient()
    // client.get('/sanctum/csrf-cookie').then(response => {})

    const that = this

    client.get('/api/order?token=' + this.token + '&date=' + this.date)
      .then(function (response) {
        that.orderData = response.data.order
      })
      .catch(function (error) {
        console.log(error)
      })
      .finally(function () {
        that.loading = false
      })
  },
  computed: {
    dateFormatted () {
      return this.date.split('-').reverse().join('.')
    }
  },

  methods: {
    toggleMenu () {
      this.$refs.refMenu.toggle()
    },
    goTo (path) {
      this.$router.push({ path: path })
    }
  }
}
</script>
<style>
h1 {
  line-height: 1em;
  font-size: 26px;
  margin-bottom: 10px;
}

.v-data-table {
  padding: 10px 0;
}

</style>
